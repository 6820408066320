import React, { useState } from "react"
import { Link } from "gatsby";
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
function CovidModal() {
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Modal show={show} onHide={handleClose}  backdrop="static" centered className="covid-popup-content">
        <Modal.Header closeButton className="covid-popup-content-header"> 
        </Modal.Header>
        <Modal.Body>
          <Row>  
            <Col md={{ span: 10, offset: 1 }}> <h3 className="covid-popup-content-info">Information from The Fox Club</h3></Col>
          </Row>        
          <Row>
            <Col md={{ span: 10, offset: 1 }}> <p className="covid-popup-content-section"><Link to="information-from-the-fox-club">Click here to know more</Link></p></Col>
          </Row>
          <Row> 
          </Row>
          <Row>
            <Col md={{ span: 10, offset: 1 }}>&nbsp;</Col>
          </Row>
          <Row>
            <Col md={{ span: 10, offset: 1 }}><p className="covid-popup-content-section">EXCLUSIVE PRIVATE HIRE - <Link to="exclusive-private-hire">More Details</Link></p></Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
class Covid extends React.Component {
  render() {
    return (
      <>
      <CovidModal />
      </>
    )
  }
}
export default Covid
